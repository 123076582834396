import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import MobilePageLayout, {
  HeaderWrapper,
  DividerWide,
} from '../components/MobilePageLayout'
import { DividerColor } from '../components/Divider'
import { LinkButton } from '../components/Button'

const MarginHeader = styled(HeaderWrapper)`
  margin-bottom: 1rem;
`

const MarginParagraph = styled.p`
  margin-bottom: 1.4rem;
`

interface ConfirmationProps {
  location: {
    state: {
      email: string
    }
  }
}

const ConfirmationPage: React.FC<ConfirmationProps> = ({
  location,
}: ConfirmationProps) => {
  return (
    <Layout>
      <SEO title="Beställning slutförd" />
      <MobilePageLayout>
        <MarginHeader>
          <h3>Tack!</h3>
        </MarginHeader>
        <MarginParagraph>
          Din beställning är slutförd! Vi har skickat en bekräftelse till
          e-postadressen {location.state && location.state.email}. Vi hörs snart
          👋
        </MarginParagraph>
        <DividerWide assetColor={DividerColor.Pink} />
        <LinkButton link="/" wide={true} text="Till startsidan" />
      </MobilePageLayout>
    </Layout>
  )
}

export default ConfirmationPage
